import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;


async function setObject() {
  await Storage.set({
    key: 'user',
    value: JSON.stringify({
      id: 1,
      name: 'Max'
    })
  });
}

// JSON "get" example
export async function getObject(key:string) {
  const ret = await Storage.get({ key: key });
  const user = JSON.parse(ret.value!);
}

export async function setItem(key:string, value:string) {
  await Storage.set({
    key: key,
    value: value
  });
}

export async function getItem(key:string) {
  const { value } = await Storage.get({ key: key });
  //console.log('Got item: ', value);
  return value;
}

export async function removeItem(key:string) {
  await Storage.remove({ key: key });
}

export async function keys() {
  const { keys } = await Storage.keys();
  alert('Got keys: ' + keys);
}

export async function clear() {
  await Storage.clear();
}

export async function firstVisit(){
  let first = await getItem("first")
  if(first != "visited")
    return true;
  return false;
}

export async function setVisited(){
  setItem("first", "visited")
}