import React, {useState} from 'react';
import { IonToast, IonTextarea, useIonViewWillEnter, IonAlert, IonInput, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { caretUpOutline,caretDownOutline,removeOutline } from 'ionicons/icons'
import { CreateAnimation} from '@ionic/react';

import './Card.css';
import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';
import * as StorageService from '../services/storageService';

import EmailAlert from './EmailAlert';

interface InputCardProps {
  name: string;
  refresh(): any;
}

const InputCard: React.FC<InputCardProps> = ({ name, refresh}) => {
  const [text, setText] = useState<string>();
  const [school, setSchool] = useState<string | null>();
  const [emailAlert, setEmailAlert] = useState(false);
  const [checkEmailAlert, setCheckEmailAlert] = useState(false);
  const [email, setEmail] = useState<string>();

  const [showToast, setShowToast] = useState(false);

  const db = FirebaseService.getdb();
  const fb = FirebaseService.getFb();
  fb.auth().onAuthStateChanged(async (user) =>{
    updateAuth();
  });

  async function updateAuth(){
    if(AuthService.isLoggedIn()){
      const school = await AuthService.getSchool()
      setSchool(school)
    } else {
      setSchool(null)
    }
  }

  async function clck(){
    const login = await AuthService.isLoggedIn();
    const hasEmail = await AuthService.hasEmail();

    if(login == false || login == null){
      setEmailAlert(true);
    } else if(hasEmail == false)
      setCheckEmailAlert(true);
    else if(text != null && text != ""){
     db.collection("posts").add({
       uid: await AuthService.getID(),
       text: text,
       school: await AuthService.getSchool(),
       timestamp: FirebaseService.getServerTime(),
       numPoints: 0,
       numComments: 0,
       deleted: false,
     }).then(function(){
       refresh()
     })
     setShowToast(true);
     setText("");
    }
  }

  function checkEmail(email:string){

  }

  useIonViewWillEnter(async () => {
    updateAuth();
  });

  return (
    <div className="card">
      <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Post successfully sent"
          duration={400}
      />
      <EmailAlert alertMessage="You need a valid .edu email to post" 
      emailAlert={emailAlert} checkEmailAlert={checkEmailAlert} setEmailAlert={setEmailAlert} setCheckEmailAlert={setCheckEmailAlert}/>
      <IonCard class="input-card-small-margin">
      	<IonCardContent class="less-padding">
      		<IonGrid>
      		<IonRow>
      			<IonCol>
      			 <IonTextarea class="input-text" value={text} autoGrow={true} maxlength={500} rows={2} placeholder="What's going on" onIonChange={e => setText(e.detail.value!)}/>
      			</IonCol>
      		</IonRow>
      		<IonRow>
      			<IonCol class="meta-text">
      			{school && <div><div className={school + "-tag"}></div><div className="meta-text">{school}</div></div>}
            {school == null && "You are not logged in"}
            {school == "" && "You don't have an edu email"}
      			</IonCol>
      			<IonCol class="right">
      			<IonButton class="post-button" fill="solid" size="small" onClick={() => clck()}>
            POST
            </IonButton>   			
      			</IonCol>
      		</IonRow>
      		</IonGrid>
      	</IonCardContent>
      </IonCard>
    </div>
  );
};

export default InputCard;