import Menu from './components/Menu';
import Page from './pages/Page';
import Profile from './pages/Profile';
import Reports from './pages/Reports';
import About from './pages/About';
import CommentPage from './pages/CommentPage';
import Login from './pages/Login';
import Logout from './pages/Logout';
import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {
  document.title = "Orru"
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" class="mainContainer" when="(orientation:landscape) and (min-device-width: 700px)">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/page/:name" component={Page} exact />
            <Redirect from="/" to="/page/Home" exact />
            <Route path="/login" component={Login} exact/>
            <Route path="/logout" component={Logout} exact/>
            <Route path="/profile" component={Profile} exact/>
            <Route path="/reports" component={Reports} exact/>
            <Route path="/about" component={About} exact/>
            <Route path="/post/:docID" component={CommentPage} exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
