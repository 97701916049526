import * as FirebaseService from './firebaseService';
import * as StorageService from './storageService';
import "firebase";
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import React, { FunctionComponent, useState } from 'react';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: 'http://orru.app/login',//'https://orru.app/login',//'https://anonfeed-f014f.web.app/login',//http://localhost:8100/login
  // This must be true.
  handleCodeInApp: true,
};

FirebaseService.getFb().auth().onAuthStateChanged((user) =>{
    if(user){
        //StorageService.setItem("user", JSON.stringify(user))
        addToDatabase();
    }else{
        StorageService.setItem("user", "")
    }
});

export async function verifyEmail(email:string){
	FirebaseService.getFb().auth().sendSignInLinkToEmail(email, actionCodeSettings)
	  .then(function() {
	    // The link was successfully sent. Inform the user.
	    // Save the email locally so you don't need to ask the user for it again
	    // if they open the link on the same device.
	    //window.localStorage.setItem('emailForSignIn', email);
	    StorageService.setItem("email", email);
	    //alert("Email Sent")
	  })
	  .catch(function(error) {
	    alert("There was an error: " + error.code)
	  });
}

export async function verifyConfirmed(url:string){
	if (FirebaseService.getFb().auth().isSignInWithEmailLink(url)) {
		const email = await StorageService.getItem("email")
		FirebaseService.getFb().auth().signInWithEmailLink(email!, url)
	    .then(function(result) {
	      // Clear email from storage.
	      // window.localStorage.removeItem('emailForSignIn');
	      // You can access the new user via result.user
	      // Additional user info profile not available via:
	      // result.additionalUserInfo.profile == null
	      // You can check if the user is new or existing:
	      // result.additionalUserInfo.isNewUser
	      alert("Welcome, you're logged in!")
	      return result.user;
	    })
	    .catch(function(error) {
	      // Some error occurred, you can inspect the code: error.code
	      // Common errors could be invalid email and invalid or expired OTPs.
	      alert("There was an error: " + error.code);
	    });
	} 
}

export async function hasEmail(){
	const testEmail = await StorageService.getItem("email");
	const user = await FirebaseService.getFb().auth().currentUser
	if(user != null){
		const email = await FirebaseService.getFb().auth().currentUser!.email
		StorageService.setItem("email", email!);
		return true;
	} else if(testEmail == null){
		return false;
	}else{
		return true;
	}
}

export function hasVerified(){
	var user = FirebaseService.getFb().auth().currentUser;
	if (user) {
	  return true;
	} else {
	  return false;
	}
}

export async function getEmail(){
	const email = await StorageService.getItem("email")
	alert(email)
	if(hasEmail()){
		return email;
	} else {
		const id = await getID();
		const user = await FirebaseService.getdb().collection('users').doc(id).get()
		const email:string = user.get("email")
		if(email != null){
			return email;
		} else {
			return "";
		}
	}
}

export async function isLoggedIn(){
	const user = await StorageService.getItem("user");
	if(user && user!.length != 0){
		return true;
	} else {
		const uid = await FirebaseService.getFb().auth().currentUser
		if(uid != null){
			StorageService.setItem("user", uid.uid)
		} else{
			return false;
		}
		return false;
	}
}

export async function getID(){
	// const user = await StorageService.getItem("user");
	// if(user && user != ""){
	// 	try{
	// 		return JSON.parse(user!).uid;
	// 	}
	// 	catch(e){
	// 		console.log(e)
	// 	}
	// } else {
	// 	const uid = await FirebaseService.getFb().auth().currentUser
	// 	if(uid != null){
	// 		StorageService.setItem("user", uid.uid)
	// 	} else{
	// 		return null;
	// 	}
	// }
	const uid = await FirebaseService.getFb().auth().currentUser
	if(uid != null){
		return uid.uid;
	} else{
		return undefined;
	}
}

export async function getSchool(){
	const id = await getID();
	if(id == null)
		return null;
	const email = await FirebaseService.getdb().collection('users').doc(id).get()
	const school:string = email.get("school")
	return school
}

export async function checkInDatabase(){
	const id = await getID();
	if(id == null)
		return false
	const value = await FirebaseService.getdb().collection('users').doc(id).get()
	if(value!)
		return value.exists
	else
		return false
}

export async function isAdmin(){
	const id = await getID();
	if(id == null)
		return false
	const value = await FirebaseService.getdb().collection('admins').doc(id).get()
	if(value!)
		return value.exists
	else
		return false
}

export async function addToDatabase(){
	try{
		const id = await getID();
		const inDB = await FirebaseService.getdb().collection('users').doc(id).get()
		if(!inDB.exists){
			const email = await FirebaseService.getFb().auth().currentUser!.email
			const school = email!.slice(email!.indexOf("@")+1, email!.lastIndexOf("."))
			const data = {uid: id, email: email!, school: school!}
			const value = await FirebaseService.getdb().collection('users').doc(id).set(data)
		} else {
			//FirebaseService.getdb().collection('users').doc(id).update({lastLogin: FirebaseService.getServerTime()})
		}
	}catch(e){
		console.log(e)
	}
	
}

export async function logout(){
	const isin = await isLoggedIn()
	if(isin){
		FirebaseService.getFb().auth().signOut().then(function() {
			StorageService.removeItem("user")
			StorageService.removeItem("email")
			alert("Signed out")	
		}, function(error){
			alert("Error: " + error)	
		});
	}
	else {
		alert("You aren't logged in")
	}
}