import * as fb from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
}

fb.initializeApp(config);
const db = fb.firestore();

/*export const getFeedList = () => {
	const [value, loading, error] = useCollectionOnce(fb.firestore().collection('items'));
	return [value, loading, error];
}*/

export const getFb = () => {
	return fb;
}

export const getdb = () => {
	return db;
}

export const getServerTime = () => {
	return fb.firestore.FieldValue.serverTimestamp()
}

export async function getPost(id:string){
	return db.collection('users').doc(id).get()
}