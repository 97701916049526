import { useIonViewWillEnter, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Card from '../components/Card';
import MainList from '../components/MainList';
import './Page.css';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { Redirect } from "react-router-dom";

import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';


const Logout: React.FC = () => {
  const [redir, setRedir] = useState<boolean>(false);
  
  useIonViewWillEnter(async () => {
    AuthService.logout().then(function () {
      setRedir(true);
    })
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Logout</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {redir && <Redirect to='/'/>}
      </IonContent>
    </IonPage>
  );
};

export default Logout;
