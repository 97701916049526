import { useIonViewWillEnter, IonSegment, IonSegmentButton, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Report from '../components/Report';
import ProfileList from '../components/ProfileList';
import './Page.css';
import { Redirect } from "react-router-dom";

import * as firebase from "firebase"

import * as AuthService from '../services/userauth';
import * as FirebaseService from '../services/firebaseService';

const Reports: React.FC = () => {
  const [redir, setRedir] = useState<boolean>(false);
  const [points, setPoints] = useState<number>(0);
  const [feedList, setFeedList] = useState<firebase.firestore.DocumentData[]>([]);
  const db = FirebaseService.getdb();

  useIonViewWillEnter(async () => {
    const isLoggedIn = await AuthService.isLoggedIn();
    if(!isLoggedIn)
      setRedir(true);
    else 
      getPoints();
  });

  async function getPoints(){
    const uid = await AuthService.getID();
    const feed = await db.collection('reports').orderBy('timestamp', "desc").get()
    let docs:firebase.firestore.DocumentData[] = []
    let ids:string[] = []
    for(const item of feed!.docs){
      let pid = await item.get("pid")
      if(ids.indexOf(pid) == -1){
        let temp = await db.collection('posts').doc(pid).get(); 
        docs.push(temp);
        ids.push(pid)
      }
    }
    setFeedList(docs)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Reports</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
      {feedList && (
          <div>
            {feedList.map((doc, ind) => (
              <Report key={doc.id} doc={doc}/>
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Reports;
