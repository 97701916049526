import React, {useState} from 'react';
import { useParams } from 'react-router';
import { IonItemDivider, IonSpinner, IonIcon, IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton, IonLabel, IonAlert, IonInfiniteScroll, IonInfiniteScrollContent, useIonViewWillEnter } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import {sadOutline} from 'ionicons/icons';
import Card from '../components/Card';
import InputCard from '../components/InputCard';

import * as firebase from "firebase"

import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';
import * as StorageService from '../services/storageService';
import * as TrackService from '../services/trackService'
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import './Card.css';

interface ListProps {
  name: string;
  hot: string;
  school: string;
}

const MainList: React.FC<ListProps> = ({ name, hot, school }) => {
  const [items, setItems] = useState<string[]>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const [feedList, setFeedList] = useState<firebase.firestore.DocumentData[]>([]);
  const [firstFeed, setFirstFeed] = useState<boolean>(true);
  const [myHot, setMyHot] = useState<string>(hot);

  const [mySchool, setMySchool] = useState<string | null>();
  const [currentSchool, setCurrentSchool] = useState<string | null>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const db = FirebaseService.getdb();
  const fb = FirebaseService.getFb();
  fb.auth().onAuthStateChanged(async (user) =>{
    const tempSchool = await AuthService.getSchool();
    setMySchool(tempSchool)
  });


  React.useEffect(() => {
    async function update(){
      if(hot != myHot || school != currentSchool){
        //window.scrollTo(0, 0);
        setMyHot(hot);
        setFeedList([]);
        setFirstFeed(true);
        fetchFeed(true, hot);
        setCurrentSchool(school)
        TrackService.addPage(window.location.pathname)
      }
    }
    update();
  })

  function refreshFeed(event:CustomEvent<RefresherEventDetail>){
    fetchFeed(true, hot).then(function(){
      event.detail.complete();
    });
  }

  function refreshFeedDefault(){
    fetchFeed(true, hot)
  }

  async function fetchFeed(first:boolean, isHot:string){
    let newSort = db.collection('posts').orderBy('numPoints', "desc").orderBy('timestamp', "desc").where("deleted", "==", false).where("numPoints", ">", -5);
    let topSort = db.collection('posts').orderBy('numPoints', "desc").where("deleted", "==", false).where("numPoints", ">", -5);
    let hotSort = db.collection('posts').orderBy('numPoints', "desc").orderBy('hotScore', "desc").where("deleted", "==", false).where("numPoints", ">", -5);
    if(school != "Home"){
      newSort = newSort.where("school", "==", school)
      topSort = topSort.where("school", "==", school)
      hotSort = hotSort.where("school", "==", school)
    }
    if(first){
      setIsLoading(true)
      let feed = null;
      if(isHot == "top"){
        feed = topSort.limit(6)
      } else if(isHot == "new") {
        feed = newSort.limit(6)
      } else{
        feed = hotSort.limit(6)
      }
      feed = await feed.get();
      setFeedList(feed!.docs)
      if(!feed!.empty){
        setFirstFeed(false);
      }
      setIsLoading(false)
    }
    else {
      const last = feedList![feedList!.length-1];
      let feed = null;
      if(isHot == "top"){
        feed = await topSort.startAfter(last).limit(6).get()
      } else if(isHot == "new") {
        feed = await newSort.startAfter(last).limit(6).get()
      } else{
        feed = await hotSort.startAfter(last).limit(6).get()
      }
      setFeedList(feedList.concat(feed!.docs))
    }
  }

  useIonViewWillEnter(async () => {
    if(feedList == null || feedList.length == 0)
      await fetchFeed(firstFeed, myHot);
    const tempSchool = await AuthService.getSchool();
    setMySchool(tempSchool)
  });

  async function searchNext($event: CustomEvent<void>){
    await fetchFeed(firstFeed, myHot);
    ($event.target as HTMLIonInfiniteScrollElement).complete()
  }

  function setHot(){
    console.log("hot")
  }
  
  return (
    <div className="list">
      <IonRefresher slot="fixed" onIonRefresh={refreshFeed}>
        <IonRefresherContent
        pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing...">
            
          </IonRefresherContent>
      </IonRefresher>
      {(school == mySchool || school=="Home") && <InputCard name="test" refresh={refreshFeedDefault}/>}
      <IonItemDivider className="divider">
      </IonItemDivider>
      {feedList && (
          <div>
            {feedList.map(doc => (
              <Card key={doc.id} doc={doc} fullDisplay={true} />
            ))}
          </div>
        )}
      {((feedList.length == 0 || feedList == null) && !isLoading)&& <div className="center-main">
      <IonIcon slot="start" icon={sadOutline} />
      &nbsp;
      <IonLabel>Nothing Here</IonLabel></div>}

      {isLoading && <div className="center-main"><IonSpinner name="circles" color="primary" /></div>}

      <IonInfiniteScroll threshold="50px" disabled={disableInfiniteScroll}
                             onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
                loadingText="Loading more...">
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
      
    </div>
  );
};

export default MainList;