import { useIonViewWillEnter, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Card from '../components/Card';
import MainList from '../components/MainList';
import './Page.css';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { Redirect } from "react-router-dom";

import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';


const Login: React.FC = () => {
  const [redir, setRedir] = useState<boolean>(false);
  
  useIonViewWillEnter(async () => {
    const url = window.location.href
    if (url.includes('signIn')) {
      confirmSignIn(url);
    }
  });

  async function confirmSignIn(url:string) {
    try {
      AuthService.verifyConfirmed(url).then(() => {
        setRedir(true);
      });
    } catch (err) {
      alert("There was an error: " + err.message)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {redir && <Redirect to='/'/>}
      </IonContent>
    </IonPage>
  );
};

export default Login;
