import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonAvatar,
  IonAlert,
  IonToast,
} from '@ionic/react';

import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { bugOutline, atOutline, helpCircleOutline, personCircleOutline, homeOutline, logInOutline, logOutOutline, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import Card from '../components/Card';
import quicklogo from '../assets/logo2.png'
import darklogo from '../assets/logodark.png'

import * as StorageService from '../services/storageService';
import * as AuthService from '../services/userauth';
import * as FirebaseService from '../services/firebaseService';
import * as firebase from "firebase"

import EmailAlert from '../components/EmailAlert';

import { menuController } from "@ionic/core";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Menu: React.FC = () => {
  const location = useLocation();

  const [school, setSchool] = useState<string | null>(null);
  const [karma, setKarma] = useState<number>(0);
  const [loggedIn, setLoggedin] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);

  const [emailAlert, setEmailAlert] = useState(false);
  const [checkEmailAlert, setCheckEmailAlert] = useState(false);

  const [schoolList, setSchoolList] = useState<firebase.firestore.DocumentData[]>([]);

  const [aboutAlert, setAboutAlert] = useState(false);
  const [suggestionAlert, setSuggestionAlert] = useState(false);
  const [bugAlert, setBugAlert] = useState(false);

  const [showToast, setShowToast] = useState(false);

  const db = FirebaseService.getdb();
  const fb = FirebaseService.getFb();
  fb.auth().onAuthStateChanged(async (user) =>{
    await updateAuth();
  });

  async function login(){
    const login = await AuthService.isLoggedIn();
    const hasEmail = await AuthService.hasEmail();

    if(login == false || login == null){
      setEmailAlert(true);
    } else if(hasEmail == false) {
      setCheckEmailAlert(true);
    } else {
      setLoggedin(true)
    }
  }

  async function updateAuth(){
    const login = await AuthService.isLoggedIn()
    setLoggedin(login)
    if(login){
      const schoolTemp = await AuthService.getSchool();
      setSchool(schoolTemp)
      const isAdmin = await AuthService.isAdmin()
      setAdmin(isAdmin)
    }else{
      setSchool(null);
    }
  }

  async function getSchoolList(){
    const tempList = await FirebaseService.getdb().collection("schools").orderBy("school").get()
    setSchoolList(tempList!.docs)
  }

  useEffect(() => {
    async function getID() {
      updateAuth();
      getSchoolList();
      let temp = await StorageService.firstVisit()
      if(temp)
        setAboutAlert(true);
    }
    getID();
  }, []);

  async function checkAuth(){
    const login = await AuthService.isLoggedIn()
    setLoggedin(login)
    const isAdmin = await AuthService.isAdmin()
    setAdmin(isAdmin)
  }

  function logout(){
    setLoggedin(false)
  }

  async function sendSuggestion(text:string){
    if(text != null && text != ""){
      db.collection("suggestions").add({
         uid: await AuthService.getID(),
         text: text,
         timestamp: FirebaseService.getServerTime(),
      })
    }
  }

  async function sendBug(text:string){
    if(text != null && text != ""){
      db.collection("bugs").add({
         uid: await AuthService.getID(),
         text: text,
         timestamp: FirebaseService.getServerTime(),
      })
    }
  }

  return (
    <IonMenu contentId="main" type="overlay" className="test-pane">
      <IonContent className="test-menu">
      <IonAlert
            isOpen={aboutAlert}
            onDidDismiss={() => {setAboutAlert(false); StorageService.setVisited()}}
            header={'Cookies'}
            message={'By continuing to browse this site, you agree to the use of cookies and terms and conditions as outlined in the Terms of Service. \n These can be found at: <a href=\"/about\">orru.app/about</a> '}
            buttons={['OK']}
          />

      <IonAlert
            isOpen={suggestionAlert}
            onDidDismiss={() => setSuggestionAlert(false)}
            header={'Suggestion Box'}
            message={'Feel free to enter any suggestions below: '}
            inputs={[{
                name: 'sugg',
                type: 'textarea',
                placeholder: '...'
              },]}
            buttons={[{
                text: 'Submit',
                handler:(suggData) => {
                  sendSuggestion(suggData.sugg);
                  setShowToast(true);
                }
              },
              {text: "Cancel"}]}
          />
      <IonAlert
            isOpen={bugAlert}
            onDidDismiss={() => setBugAlert(false)}
            header={'Bug Report'}
            message={'Please describe the bug in as much detail as possible including what led to the bug: '}
            inputs={[{
                name: 'bug',
                type: 'textarea',
                placeholder: '...'
              },]}
            buttons={[{
                text: 'Submit',
                handler:(bugData) => {
                  sendBug(bugData.bug);
                  setShowToast(true);
                }
              },
              {text: "Cancel"}]}
          />

      <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Sent. Thank you!"
          duration={1000}
      />
      <EmailAlert alertMessage="You need a valid .edu email to post" 
      emailAlert={emailAlert} checkEmailAlert={checkEmailAlert} setEmailAlert={setEmailAlert} setCheckEmailAlert={setCheckEmailAlert}/>
        
        <IonList id="inbox-list">
        <div className="center-item">
        <picture className="menu-logo">
          <source 
            srcSet={darklogo}
            media="(prefers-color-scheme: dark)" />
          <source 
            srcSet={quicklogo}
            media="(prefers-color-scheme: light)" />
          <img  src={quicklogo} />
        </picture>
          
        </div>
          {school && <IonListHeader class="list-header"><div className={school + "-big-tag"}> </div><div className="my-school-text">{school}</div></IonListHeader>}
          <IonItem className={location.pathname === '/page/Home' ? 'selected' : ''} routerLink={'/page/Home'} routerDirection="none" lines="none" detail={false} onClick={async () => await menuController.toggle()}>
            <IonIcon slot="start" icon={homeOutline} />
            <IonLabel>{'Home'}</IonLabel>
          </IonItem>

          {loggedIn && 
          <IonItem className={location.pathname === '/Profile' ? 'selected' : ''} routerLink={'/Profile'} routerDirection="none" lines="none" detail={false} onClick={async () => await menuController.toggle()}>
            <IonIcon slot="start" icon={personCircleOutline} />
            <IonLabel>{'Profile'}</IonLabel>
          </IonItem>}

          {admin && 
          <IonItem className={location.pathname === '/Reports' ? 'selected' : ''} routerLink={'/Reports'} routerDirection="none" lines="none" detail={false} onClick={async () => await menuController.toggle()}>
            <IonIcon slot="start" icon={personCircleOutline} />
            <IonLabel>{'Reports'}</IonLabel>
          </IonItem>}
          
          {loggedIn && 
          (<IonItem className={location.pathname === '/Logout' ? 'selected' : ''} routerLink={'/Logout'} routerDirection="none" lines="none" detail={false} onClick={logout}>
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>{'Logout'}</IonLabel>
          </IonItem>)}
          {!loggedIn && 
          (<IonItem className={location.pathname === '/Login' ? 'selected' : ''} onClick={login} lines="none" detail={false} type={"button"} button={true}>
            <IonIcon slot="start" icon={logInOutline} />
            <IonLabel>{'Login'}</IonLabel>
          </IonItem>)}
        </IonList>

        <IonList id="inbox-list">
        <IonListHeader>Schools</IonListHeader>
          {schoolList && (
          <div>
            {schoolList.map(doc => (
              <IonItem key={doc!.id} routerLink={'/page/' + doc.get("school")} routerDirection="none" lines="none" detail={false} onClick={async () => await menuController.toggle()}><div className={doc.get("school") + "-tag"}> </div> <div className="school-item-text">{doc.get("school")} </div></IonItem>
            ))}
          </div>
        )}
        </IonList>

        <IonList class="settings-list">
          <IonItem className="settings-item" routerLink={'/About'} routerDirection="none" lines="none" detail={false} onClick={async () => await menuController.toggle()}>
              <IonIcon slot="start" icon={helpCircleOutline}/>
              <IonLabel>{'About'}</IonLabel>
          </IonItem>
          <IonItem lines="none" className="settings-item" button={true} onClick={() => setSuggestionAlert(true)} detail={false}>
              <IonIcon slot="start" icon={atOutline} />
              <IonLabel>{'Send Suggestion'}</IonLabel>
          </IonItem>
          <IonItem lines="none" className="settings-item" button={true} onClick={() => setBugAlert(true)} detail={false}>
              <IonIcon slot="start" icon={bugOutline} />
              <IonLabel>{'Report Bug'}</IonLabel>
          </IonItem>
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
