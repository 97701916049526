import React, {useState, useEffect} from 'react';
import { IonToast, useIonViewWillEnter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { caretUpOutline,caretDownOutline,removeOutline } from 'ionicons/icons'
import * as firebase from "firebase"
import * as AuthService from '../services/userauth';
import * as FirebaseService from '../services/firebaseService';

import { useDocumentOnce } from 'react-firebase-hooks/firestore';

import './Card.css';

interface CommentVoteProps {
  doc: firebase.firestore.DocumentData
}

const CommentVoteBlock: React.FC<CommentVoteProps> = ({ doc}) => {
  const db = FirebaseService.getdb();
  const [points, uloading, uerror] = useDocumentOnce(db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id));
  const [authID, setAuthID] = useState<string>();
  const [isUpvoted, setUpvoted] = useState<boolean>();
  const [isDownvoted, setDownvoted] = useState<boolean>();

  const [myVote, setMyVote] = useState<number>(0);
  const [startVote, setStartVote] = useState<number>(0);

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    async function getUpvote(){
      const login = await AuthService.isLoggedIn();
      if(login){
        const aid = await AuthService.getID()
        const upCheck = await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("upvotes").doc(aid).get()
        setUpvoted(upCheck.exists)
        if(upCheck.exists){
          setMyVote(1)
          setStartVote(-1)
        }
        const downCheck = await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("downvotes").doc(aid).get()
        setDownvoted(downCheck.exists)
        if(downCheck.exists){
          setMyVote(-1)
          setStartVote(1)
        }
      }
    }
    if(isUpvoted == null){
      getUpvote();
    }
  }, []);

  async function adjustUpvote(){
    const login = await AuthService.isLoggedIn();
    const hasEmail = await AuthService.hasEmail();
    
    if(login == false){
      setShowToast(true)
    } else if(hasEmail == false)
      setShowToast(true)
    else{
      const aid = await AuthService.getID()
      await setAuthID(aid)
      const docCheck = await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("upvotes").doc(aid).get()
      if(docCheck.exists){
        await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("upvotes").doc(aid).delete()
        setUpvoted(false)
        setMyVote(0)
      } else{
        await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("upvotes").doc(aid).set({
           uid: aid,
           timsetamp: FirebaseService.getServerTime()
        })
        const downCheck = await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("downvotes").doc(aid).get()
        if(downCheck.exists){
          await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("downvotes").doc(aid).delete()
          setDownvoted(false)
        }
        setUpvoted(true)
        setMyVote(1)
      }
    }
  }
  async function adjustDownvote(){
    const login = await AuthService.isLoggedIn();
    const hasEmail = await AuthService.hasEmail();
    
    if(login == false){
      setShowToast(true)
    } else if(hasEmail == false)
      setShowToast(true)
    else{
      const aid = await AuthService.getID()
      await setAuthID(aid)
      const docCheck = await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("downvotes").doc(aid).get()
      if(docCheck.exists){
        await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("downvotes").doc(aid).delete()
        setDownvoted(false)
        setMyVote(0)
      } else{
        await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("downvotes").doc(aid).set({
           uid: aid,
           timsetamp: FirebaseService.getServerTime()
        })
        const upCheck = await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("upvotes").doc(aid).get()
        if(upCheck.exists){
          await db.collection('posts').doc(doc.ref.parent.parent.id).collection("comments").doc(doc.id).collection("upvotes").doc(aid).delete()
          setUpvoted(false)
        }
        setDownvoted(true)
        setMyVote(-1)
      }
    }
  }

  return (
    <div className="vote-block">
    <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Please login to vote"
          duration={3000}
          color={"danger"}
      />
      <IonRow class="vote-text">
      <IonButton class="remove-padding" fill="clear" size="small" onClick={adjustUpvote}>
        <IonIcon class="remove-padding" color={isUpvoted ? "primary" : "dark"} size="small" icon={caretUpOutline} />
      </IonButton>
      </IonRow >
      <IonRow class="vote-text">
        {points && (points.get("numPoints") + myVote + startVote)}
      </IonRow>
      <IonRow class="vote-text">
      <IonButton class="remove-padding" fill="clear" size="small" onClick={adjustDownvote}>
        <IonIcon class="remove-padding" color={isDownvoted ? "danger" : "dark"} size="small" icon={caretDownOutline} />
      </IonButton>
      </IonRow>
    </div>
  );
};

export default CommentVoteBlock;