import React, {useEffect, useState} from 'react';
import { IonToast, IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { flagOutline, caretUpOutline,caretDownOutline,removeOutline } from 'ionicons/icons'
import * as firebase from "firebase"
import * as AuthService from '../services/userauth';
import * as FirebaseService from '../services/firebaseService';
import TimeAgo from 'react-timeago'

import CommentVoteBlock from './CommentVoteBlock'

import './Card.css';

interface CommentProps {
  doc: firebase.firestore.DocumentData
}

const Comment: React.FC<CommentProps> = ({ doc}) => {
	const text = doc!.get("text")
	const school = doc!.get("school")
	const time = doc!.get("timestamp", {serverTimestamps: "estimate"}).seconds * 1000
  const cid = doc!.get("uid")
  const [match, setMatch] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isDeleted, setDeleted] = useState<boolean>(doc!.get("deleted"));
  const [admin, setAdmin] = useState<boolean>(false);

  const [showReport, setReportAlert] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  const db = FirebaseService.getdb();
  useEffect(() => {
    async function getID() {
      const uid = await AuthService.getID()
      if(uid == cid){
        setMatch(true);
      }
      const isAdmin = await AuthService.isAdmin();
      setAdmin(isAdmin);
    }
    getID();
  }, []);

  function clck(){
    setShowAlert(true);
  }

  async function sendReport(text:string){
    db.collection("reports").add({
       uid: await AuthService.getID(),
       text: text,
       timestamp: FirebaseService.getServerTime(),
       cid: doc!.id,
       pid: doc!.ref.parent.parent.id
     })
  }

  return (
    <div>
    <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='./Card.css'
          header={'Delete Comment'}
          message={'Are you sure you want to delete your comment?'}
          buttons={[{
              text: 'Yes',
              handler:async () => {
                const res = await doc.ref.set({deleted: true},{merge: true})
                setDeleted(true)
              }
            },
            {text: "Cancel"}]}
        />
    <IonAlert
            isOpen={showReport}
            onDidDismiss={() => setReportAlert(false)}
            header={'Report Comment'}
            inputs={[{
                name: 'rep',
                type: 'textarea',
                placeholder: '...'
              },]}
            buttons={[{
                text: 'Submit',
                handler:(repData) => {
                  sendReport(repData.rep);
                  setShowToast(true);
                }
              },
              {text: "Cancel"}]}
          />
      <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Reported to our Moderation Team."
          duration={3000}
      />
    <IonItem class="comment-main">
      		<IonGrid class="remove-padding">
      		<IonRow class="remove-padding">
          <IonCol size="10">
      		<IonRow class="meta-small-text">
      			<TimeAgo date={time}/>
      		</IonRow>
      			<IonRow class="comment-area">
      			{text}
      			</IonRow>
      			<IonRow class="school-text">
      			<div className={school + "-tag"}></div>
            <div className={"meta-text"}>{school}</div> 
            {!match && (<IonRow class="remove-padding">
              <IonButton class="comment-button" fill="clear" size="small" mode="ios" onClick={() => setReportAlert(true)} color="grey">
              <IonIcon class="remove-padding" size="small" icon={flagOutline} />
              </IonButton> 
            </IonRow>)}
      			</IonRow>
           </IonCol>
           <IonCol size="2">
              <CommentVoteBlock doc={doc!}/>
            </IonCol>
      		</IonRow>
          {(match || admin)&& !isDeleted && (<IonRow class="remove-padding">
              <IonButton fill="outline" size="small" color="danger" mode="ios" onClick={() => clck()}>
              Delete
              </IonButton>
            </IonRow>)}
          {isDeleted && (<IonRow class="remove-padding">
              <IonButton fill="clear" size="small" color="danger" mode="ios" disabled={true}>
              Deleted
              </IonButton>
            </IonRow>)}
      		</IonGrid>
    </IonItem>
    </div>
  );
};

export default Comment;