import React, {useState, useEffect} from 'react';
import { IonToast, IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { caretUpOutline,caretDownOutline,removeOutline } from 'ionicons/icons'
import * as firebase from "firebase"
import TimeAgo from 'react-timeago'
import * as AuthService from '../services/userauth';
import * as FirebaseService from '../services/firebaseService';

import './Card.css';

interface EmailAlertProps {
  alertMessage:string;
  emailAlert:boolean;
  checkEmailAlert:boolean;
  setEmailAlert:(value: boolean | ((prevVar: boolean) => boolean)) => void; 
  setCheckEmailAlert:(value: boolean | ((prevVar: boolean) => boolean)) => void; 
}

const EmailAlert: React.FC<EmailAlertProps> = ({alertMessage, emailAlert, checkEmailAlert, setEmailAlert, setCheckEmailAlert}) => {
  const [email, setEmail] = useState<string>();
  const [showToast, setShowToast] = useState(false);
  const [schoolList, setSchoolList] = useState<string[]>([]);

  async function getSchoolList(){
    const tempList = await FirebaseService.getdb().collection("schools").get()
    let ls:string[] = []
    tempList!.docs.forEach(function(item){ls.push(item.get("school"))})
    setSchoolList(ls)
  }

  useEffect(() => {
    async function getID() {
      getSchoolList();
    }
    getID();
  }, []);

  return (
    <div>
    <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Please check your email"
          duration={3000}
      />
    <IonAlert
          isOpen={emailAlert}
          onDidDismiss={() => setEmailAlert(false)}
          cssClass='./Card.css'
          header={'Please Login'}
          message={alertMessage + "<br/><br/>By signing up you agree to the terms and conditions at <a href=\"/about\">orru.app/about</a>"}
          inputs={[{
              name: 'email',
              type: 'text',
              placeholder: 'Email Address'
            },]}
          buttons={[{
              text: 'Submit',
              handler:(emailData) => {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //alert(schoolList.includes(emailData.email.slice(emailData.email.indexOf("@")+1, emailData.email.lastIndexOf("."))))
                if(emailData.email != null && re.test(emailData.email) && emailData.email.endsWith(".edu") && schoolList.includes(emailData.email.slice(emailData.email.indexOf("@")+1, emailData.email.lastIndexOf(".")))){
                  AuthService.verifyEmail(emailData.email);
                  setShowToast(true);
                  return true;
                } else {
                  if(schoolList.length == 0)
                    alert("Please check that you are online")
                  else
                    alert("Please enter a Five College .edu email")
                  return false;
                }
              }
            },
            {text: "Cancel"}]}
        />
       <IonAlert
          isOpen={checkEmailAlert}
          onDidDismiss={() => setCheckEmailAlert(false)}
          cssClass='./Card.css'
          header={'Please check your email'}
          buttons={[
            {text: "Ok"}]}
        />
   </div>
  );
};

export default EmailAlert;