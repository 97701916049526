import React, {useState, useEffect} from 'react';
import { IonRefresher, IonRefresherContent, IonList, IonAlert, IonInfiniteScroll, IonInfiniteScrollContent, useIonViewWillEnter } from '@ionic/react';

import Card from '../components/Card';
import Comment from '../components/Comment';
import InputCard from '../components/InputCard';

import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';
import * as StorageService from '../services/storageService';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import './Card.css';

interface CommentListProps {
  feedList: firebase.firestore.DocumentData[];
}

const CommentList: React.FC<CommentListProps> = ({ feedList }) => {
  return (
    <div className="list">
      
      {feedList && (
          <IonList className="comment-list">
            {feedList.map(doc => (

              <Comment key={doc.id} doc={doc} />
            ))}
          </IonList>
        )}
    </div>
  );
};

export default CommentList;