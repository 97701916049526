import React, {useState, useEffect} from 'react';
import { IonToast, useIonViewWillEnter, IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { flagOutline, caretUpOutline,caretDownOutline,removeOutline, chatboxOutline } from 'ionicons/icons'
import { useHistory } from "react-router-dom";
import VoteBlock from './VoteBlock'

import * as firebase from "firebase"
import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';

import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import TimeAgo from 'react-timeago'

import './Card.css';

interface CardProps {
  doc: firebase.firestore.DocumentData
}

const Report: React.FC<CardProps> = ({ doc}) => {
	const text = doc!.get("text")
	const school = doc!.get("school")
	const time = doc!.get("timestamp", {serverTimestamps: "estimate"}).seconds * 1000
  const url = "/post/"+doc!.id
  const cid = doc!.get("uid")
  const db = FirebaseService.getdb();
  const comments = doc!.get("numComments")
  //const [comments, uloading, uerror] = useCollectionOnce(db.collection('posts').doc(doc!.id).collection("comments").where("deleted", "==", false));

  const history = useHistory();
  const [match, setMatch] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showReport, setReportAlert] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);

  const [isDeleted, setDeleted] = useState<boolean>(doc!.get("deleted"));

  const [reportList, setReportList] = useState<firebase.firestore.DocumentData[]>([]);
  useEffect(() => {
    async function getID() {
      const uid = await AuthService.getID()
      if(uid == cid){
        setMatch(true);
      }
      const isAdmin = await AuthService.isAdmin();
      setAdmin(isAdmin);
      const rep = await db.collection('reports').where("pid", "==", doc!.id).get()
      setReportList(rep!.docs);
    }
    getID();
  }, []);

  function clck(){
    setShowAlert(true);
  }

  async function clear(){
    await Promise.all(reportList!.map(async (item) => {
      item.ref.delete()
    }))
    alert("cleared reports")
  }

  function ban(){
    db.collection('banned').doc(cid).set({
       uid: cid,
       timestamp: FirebaseService.getServerTime(),
     })
    alert("banned " + cid)
  }

  const routeChange = () =>{ 
    let path = url; 
    history.push(path);
  }

  async function sendReport(text:string){
    db.collection("reports").add({
       uid: await AuthService.getID(),
       text: text,
       timestamp: FirebaseService.getServerTime(),
       pid: doc!.id,
     })
  }

  return (
    <div className="card-core">
    <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='./Card.css'
          header={'Delete Post'}
          message={'Are you sure you want to delete your post?'}
          buttons={[{
              text: 'Yes',
              handler:async () => {
                const res = await doc.ref.set({deleted: true},{merge: true})
                setDeleted(true)
              }
            },
            {text: "Cancel"}]}
        />
      <IonAlert
            isOpen={showReport}
            onDidDismiss={() => setReportAlert(false)}
            header={'Report Post'}
            inputs={[{
                name: 'rep',
                type: 'textarea',
                placeholder: '...'
              },]}
            buttons={[{
                text: 'Submit',
                handler:(repData) => {
                  sendReport(repData.rep);
                  setShowToast(true);
                }
              },
              {text: "Cancel"}]}
          />
      <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Reported to our Moderation Team."
          duration={1000}
      />
      <IonCard class="card-small-margin">
      	<IonCardContent class="less-padding">
      		<IonGrid>
      		<IonRow class="remove-padding">
      		<IonCol class="meta-small-text">
      			<TimeAgo date={time}/>
      		</IonCol>
      		</IonRow>
      		<IonRow>
      			<IonCol size="10">
      			{text}
      			</IonCol>
      			<IonCol size="2">
      				<VoteBlock doc={doc!} />
      			</IonCol>
      		</IonRow>
      		<IonRow>
      			<IonCol size={(match || admin) ? "4" : "7"}>
            <div className={school + "-tag"}></div>
      			<div className={"meta-text"}>{school}</div> 
            {school == "" && <div className={"meta-text"}>no school</div> }
            </IonCol>
            {(match || admin) && !isDeleted && <IonCol class="remove-padding"size="3">
              <IonButton class="delete-button" fill="outline" size="small" mode="ios" color="danger" onClick={() => clck()}>
              Delete
              </IonButton>
            </IonCol>}
          {isDeleted && <IonCol class="remove-padding"size="3">
              <IonButton fill="clear" size="small" color="danger" mode="ios" disabled={true}>
              Deleted
              </IonButton>
            </IonCol>}

      			
      			
      			<IonCol class="right" size="5">
            <div className="comment-button-wrapper">
      			<IonButton class="comment-button" fill="outline" size="small" mode="ios" onClick={routeChange} color="grey">
  					{comments && comments}&nbsp;{(comments != 1) && <div>Comments</div>} {(comments == 1) && <div>Comment</div>}
  				  </IonButton>  	
            </div>
      			</IonCol>
      		</IonRow>
          <IonRow>
          OP: {cid}
          </IonRow>
          <IonRow>
            {reportList.map(doc => (
              <IonItem key={doc.id}>{doc.get("text")}{doc.get("cid") != null && " !!comment!!"}</IonItem>
            ))}
          </IonRow>
          <IonButton fill="outline" size="small" color="green" mode="ios" onClick={() => clear()}>
              Clear Reports
          </IonButton>
          <IonButton class="comment-button" fill="outline" size="small" color="danger" mode="ios" onClick={() => ban()}>
              Ban User
          </IonButton>
          <IonRow>
          </IonRow>
      		</IonGrid>
      	</IonCardContent>
      </IonCard>
    </div>
  );
};
//{(comments && comments.size != 1) && <div>Comments</div>} {(comments && comments.size == 1) && <div>Comment</div>}
export default Report;