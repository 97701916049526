import { IonSegment, IonSegmentButton, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, {useEffect} from 'react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Card from '../components/Card';
import MainList from '../components/MainList';
import './Page.css';

import * as TrackService from '../services/trackService'

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const [isHot, setHot] = React.useState<string>("hot");
  const [isOn, setOn] = React.useState<boolean>(true);

  function onClick(e:CustomEvent){
    console.log(e.detail.value); 
    if(isHot != e.detail.value)
      setHot(e.detail.value)
  }

  return (
    <IonPage title="Orru">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <MainList  name={name} hot={isHot} school={name}/>
      </IonContent>
      <IonSegment onIonChange={e => {onClick(e)}} className="swidth" mode="ios" value={isHot}>
        <IonSegmentButton value="top" class="hot-color">
          <IonLabel>Top</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="hot" class="med-color">
          <IonLabel>Hot</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="new" class="cool-color">
          <IonLabel>New</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </IonPage>
  );
};

export default Page;
