import React, {useState} from 'react';
import { IonRefresher, IonRefresherContent, IonSegment, IonSegmentButton, IonLabel, IonAlert, IonInfiniteScroll, IonInfiniteScrollContent, useIonViewWillEnter } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import Card from '../components/Card';

import * as firebase from "firebase"

import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';
import * as StorageService from '../services/storageService';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import './Card.css';

const ProfileList: React.FC = () => {
  const [items, setItems] = useState<string[]>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const [feedList, setFeedList] = useState<firebase.firestore.DocumentData[]>([]);
  const [firstFeed, setFirstFeed] = useState<boolean>(true);

  const db = FirebaseService.getdb();

  function refreshFeed(event:CustomEvent<RefresherEventDetail>){
    fetchFeed(true).then(function(){
      event.detail.complete();
    });
  }

  function refreshFeedDefault(){
    fetchFeed(true)
  }

  async function fetchFeed(first:boolean){
    const uid = await AuthService.getID();
    if(first){
      const feed = await db.collection('posts').orderBy('timestamp', "desc").where("deleted", "==", false).where("uid", "==", uid).limit(6).get()
      setFeedList(feed!.docs)
      if(!feed.empty){
        setFirstFeed(false);
      }
    }
    else {
      const last = feedList![feedList!.length-1];
      const feed = await db.collection('posts').orderBy('timestamp', "desc").where("deleted", "==", false).where("uid", "==", uid).startAfter(last).limit(6).get()
      setFeedList(feedList.concat(feed!.docs))
    }
  }

  useIonViewWillEnter(async () => {
    if(feedList == null || feedList.length == 0)
      await fetchFeed(firstFeed);
  });

  async function searchNext($event: CustomEvent<void>){
    await fetchFeed(firstFeed);
    ($event.target as HTMLIonInfiniteScrollElement).complete()
  }

  return (
    <div className="list">
      <IonRefresher slot="fixed" onIonRefresh={refreshFeed}>
        <IonRefresherContent
        pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing...">
            
          </IonRefresherContent>
      </IonRefresher>
      {feedList && (
          <div>
            {feedList.map(doc => (
              <Card key={doc.id} doc={doc} fullDisplay={true} />
            ))}
          </div>
        )}

      <IonInfiniteScroll threshold="50px" disabled={disableInfiniteScroll}
                             onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
                loadingText="Loading more...">
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
      
    </div>
  );
};

export default ProfileList;