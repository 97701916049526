import { useIonViewWillEnter, IonSegment, IonSegmentButton, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Card from '../components/Card';
import ProfileList from '../components/ProfileList';
import './Page.css';
import { Redirect } from "react-router-dom";

import * as AuthService from '../services/userauth';
import * as FirebaseService from '../services/firebaseService';
import * as TrackService from '../services/trackService'

const Profile: React.FC = () => {
  const [redir, setRedir] = useState<boolean>(false);
  const [points, setPoints] = useState<number>(0);
  const db = FirebaseService.getdb();

  useIonViewWillEnter(async () => {
    const isLoggedIn = await AuthService.isLoggedIn();
    if(!isLoggedIn)
      setRedir(true);
    else 
      getPoints();
    TrackService.addPage(window.location.pathname);
  });

  async function getPoints(){
    const uid = await AuthService.getID();
    const feed = await db.collection('posts').where("deleted", "==", false).where("uid", "==", uid).get()

    let tempPoints = 0;

    if(feed !== null && !feed.empty){
      for(let i = 0; i < feed.size; i++){
        let t = await feed.docs[i].get("numPoints")
        if(t != null)
          tempPoints  += t
      }
    }

    setPoints(tempPoints)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
      <div className="profile-points">Points: {points}</div>
      {redir && <Redirect to='/'/>}
        <ProfileList/>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
