import { IonRefresher, IonRefresherContent,IonTextarea, IonItemDivider, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonInput, useIonViewWillEnter, IonBackButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';
import ExploreContainer from '../components/ExploreContainer';
import Card from '../components/Card';
import CommentList from '../components/CommentList';
import MainList from '../components/MainList';

import * as firebase from "firebase"
import * as FirebaseService from '../services/firebaseService';
import * as AuthService from '../services/userauth';
import * as TrackService from '../services/trackService'
import { useDocument } from 'react-firebase-hooks/firestore';

import './Page.css';

import EmailAlert from '../components/EmailAlert';

const CommentPage: React.FC = () => {

  const { docID } = useParams<{ docID: string}>();
  const db = FirebaseService.getdb();
  
  //const [doc, loading, error] = useDocument(db.collection('posts').doc(docID));
  const [doc, setDoc] = useState<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>();
  const [text, setText] = useState<string>();

  const [emailAlert, setEmailAlert] = useState(false);
  const [checkEmailAlert, setCheckEmailAlert] = useState(false);
  const [email, setEmail] = useState<string>();

  const [did, setDid] = useState<string>("");

  const [feedList, setFeedList] = useState<firebase.firestore.DocumentData[]>([]);

   React.useEffect(() => {
    async function update(){
      await setDid(docID)
      setFeedList([]);
      setDoc(await db.collection('posts').doc(docID).get())
      setDid(docID)
      TrackService.addPage(window.location.pathname)
      fetchFeed();
    }
    if(did != docID)
      update();
  });

  /*useIonViewWillEnter(async () => {
    alert(docID)
    await setFeedList([]);
    await setDid(docID)
    await setDoc(await db.collection('posts').doc(docID).get())
    await fetchFeed();
    alert(docID +", "+ did)
  });*/

  async function sendComment(){
    const login = await AuthService.isLoggedIn();
    const hasEmail = await AuthService.hasEmail();
    
    if(login == false){
      setEmailAlert(true);
    } else if(hasEmail == false)
      setCheckEmailAlert(true);
    else if(text != null && text != ""){
     db.collection("posts").doc(docID).collection("comments").add({
       uid: await AuthService.getID(),
       text: text,
       school: await AuthService.getSchool(),
       timestamp: FirebaseService.getServerTime(),
       numPoints: 0,
       deleted: false
     }).then(function(){
       refreshFeedDefault()
     })
     setText("");
    }
  }

  function refreshFeed(event:CustomEvent<RefresherEventDetail>){
    fetchFeed().then(function(){
      event.detail.complete();
    });
  }

  function refreshFeedDefault(){
    fetchFeed()
  }

  async function fetchFeed(){
    const feed = await db.collection('posts').doc(docID).collection("comments").orderBy('timestamp', "desc").where("deleted", "==", false).get()
    setFeedList(feed!.docs)
  }

  return (
    <IonPage>
    <EmailAlert alertMessage="You need a valid .edu email to comment" 
    emailAlert={emailAlert} checkEmailAlert={checkEmailAlert} setEmailAlert={setEmailAlert} setCheckEmailAlert={setCheckEmailAlert}/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/"/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={refreshFeed}>
        <IonRefresherContent
        pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing...">
      </IonRefresherContent>
      </IonRefresher>
        {doc && (
          <div>
            {
              <Card doc={doc} fullDisplay={false}/>
          }
          <IonItemDivider>
            <IonLabel>
              Comments
            </IonLabel>
          </IonItemDivider>
          {feedList.length > 0 && <CommentList feedList={feedList} />}
          </div>
        )}
        
      </IonContent>

      <div className="comment-input">

        <IonGrid className="comment-outline">
          <IonRow class="comment-box-container">
          <IonCol size="10" class="comment-box-container">
              <IonTextarea class="comment-text" autoGrow={true} maxlength={500} rows={2} value={text} placeholder="Comment" onIonChange={e => {setText(e.detail.value!); }}/>
          </IonCol>
          <IonCol size="2" >
            <IonButton  class="comment-submit-button" fill="solid" size="small" onClick={() => sendComment()}>
              SEND
            </IonButton>
          </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default CommentPage;
